module.exports = {
  messages: {
    A11Y_LOGO: 'Go to XING homepage',
    A11Y_SETTINGS_DROPDOWN_LABEL: 'Edit settings',
    ACTIVE_LANGUAGE: 'English',
    ACTIVITY_CENTER_CTA: 'Register now and save',
    ACTIVITY_CENTER_EMPTY_STATE_HEADLINE: 'Your activity',
    ACTIVITY_CENTER_EMPTY_STATE_TEXT:
      "Here you'll find your past activity which you can save for later in your XING profile.",
    ACTIVITY_CENTER_FOOTER_TEXT:
      'Your activity will be saved for up to 14 days.',
    ACTIVITY_CENTER_HEADLINE: 'Your activity',
    ACTIVITY_CENTER_NOTIFICATION_MESSAGE: "You've saved a job.",
    ACTIVITY_CENTER_NOTIFICATION_OPEN: 'Jetzt ansehen',
    ACTIVITY_CENTER_RECENTLY_VIEWED_JOBS_TITLE: 'Recently Viewed Jobs',
    ACTIVITY_CENTER_SAVED_JOBS_TITLE: 'Saved jobs',
    ACTIVITY_CENTER_TEXT:
      "Here's your most-recent activity which you can save for longer by joining XING free of charge.",
    ARMSTRONG_DISCO_NAVIGATION_ICON_TEXT: 'Exchange',
    BOTTOM_BAR_FIND_JOBS_LABEL: 'Find jobs',
    BOTTOM_BAR_INSIGHTS_LABEL: 'Insights',
    BOTTOM_BAR_NETWORK_LABEL: 'Network',
    BOTTOM_BAR_NOTIFICATIONS_LABEL: 'Notifications',
    BOTTOM_BAR_YOUR_JOBS_LABEL: 'Your jobs',
    BUTTON_TRY_AGAIN: 'Try again',
    FOOTER_LANGUAGES: 'Language',
    FRAME_HEADER_SEARCH: 'Search',
    FRAME_HEADER_UPSELL_PREMIUM: 'Go Premium',
    FRAME_HEADER_UPSELL_PROJOBS: 'Check out ProJobs',
    HEADER_SKIP_NAVIGATION: 'Skip navigation',
    MAINNAV_ACTIVITY_CENTER: 'Your activity',
    MAINNAV_BUSINESS_SOLUTIONS: 'Business solutions',
    MAINNAV_BUSINESS_SOLUTIONS_ADVERTISING: 'Advertising with XING',
    MAINNAV_BUSINESS_SOLUTIONS_RECRUITING: 'Recruiting with XING',
    MAINNAV_COMPANIES: 'Companies',
    MAINNAV_INSIGHTS: 'Insights',
    MAINNAV_JOBS_FIND: 'Find jobs',
    MAINNAV_LOGIN: 'Log in',
    MAINNAV_MESSAGES: 'Messages',
    MAINNAV_MYJOBS: 'Your jobs',
    MAINNAV_NETWORK: 'Network',
    MAINNAV_PRODUCTS: 'Products',
    MAINNAV_PRODUCTS_MENU_ADVERTISING: 'AdManager',
    MAINNAV_PRODUCTS_MENU_ADVERTISING_DESCRIPTION:
      'Advertise successfully on XING',
    MAINNAV_PRODUCTS_MENU_ADVERTISING_UPSELL: 'Advertise on XING',
    MAINNAV_PRODUCTS_MENU_BRAND_MANAGER: 'BrandManager',
    MAINNAV_PRODUCTS_MENU_BRAND_MANAGER_DESCRIPTION:
      'Unternehmensprofile verwalten',
    MAINNAV_PRODUCTS_MENU_HEADLINE: 'Products and services',
    MAINNAV_PRODUCTS_MENU_JOBS_POSTER: 'Job ads',
    MAINNAV_PRODUCTS_MENU_JOBS_POSTER_DESCRIPTION:
      'Stellenanzeigen veröffentlichen',
    MAINNAV_PRODUCTS_MENU_JOBS_POSTER_UPSELL: 'Stellenanzeigen erstellen',
    MAINNAV_PRODUCTS_MENU_LEBENSLAUF: 'Create a CV',
    MAINNAV_PRODUCTS_MENU_LEBENSLAUF_DESCRIPTION: 'A XING service',
    MAINNAV_PRODUCTS_MENU_RECRUITING_360_UPSELL: 'Recruiting with XING 360',
    MAINNAV_PRODUCTS_MENU_RECRUITING_360_UPSELL_BESTSELLER_BADGE: 'Bestseller',
    MAINNAV_PRODUCTS_MENU_RECRUITING_UPSELL: 'Recruiting with XING',
    MAINNAV_PRODUCTS_MENU_SEAT_MANAGER: 'LicenceManager',
    MAINNAV_PRODUCTS_MENU_SEAT_MANAGER_DESCRIPTION:
      'Premium-Mitgliedschaften verwalten',
    MAINNAV_PRODUCTS_MENU_TALENT_MANAGER: 'TalentManager',
    MAINNAV_PRODUCTS_MENU_TALENT_MANAGER_DESCRIPTION:
      'Active Sourcing in D-A-CH',
    MALT_FOOTER_COPYRIGHT_API: '© {siteoperator}  | All rights reserved',
    MEMENU_FOLLOWING_LABEL: 'Following',
    MEMENU_HELP_LABEL: 'Help',
    MEMENU_JOB_ADS_LABEL: 'Manage job ads',
    MEMENU_JOB_PREFERENCES_LABEL: 'Job preferences',
    MEMENU_MY_POSTS_LABEL: 'Your posts',
    MEMENU_PREMIUM_FOR_BASIC_USER_LABEL: 'Go Premium',
    MEMENU_PREMIUM_LABEL: 'Premium',
    MEMENU_PROBUSINESS_LABEL: 'ProBusiness',
    MEMENU_PROFILE_LABEL: 'Profile',
    MEMENU_PROJOBS_FOR_BASIC_USER_LABEL: 'Go ProJobs',
    MEMENU_PROJOBS_LABEL: 'ProJobs',
    MEMENU_SETTINGS_LABEL: 'Settings',
    NOTIFICATION_CENTER_LINK: 'Notifications',
    SEARCH_DROPDOWN_COMPANIES: 'Companies',
    SEARCH_DROPDOWN_JOBS: 'Jobs',
    SEARCH_DROPDOWN_MEMBERS: 'Members',
    SEARCH_DROPDOWN_NEWS: 'News',
    USER_FLAG_BUTTON_ARIA_LABEL: '{type} membership badge',
    VERTICAL_NAV_BUSINESS_SOLUTIONS_LABEL: 'Business solutions',
    VERTICAL_NAV_BUSINESS_SOLUTIONS_SHORT_LABEL: 'Business',
    VERTICAL_NAV_FIND_JOBS_LABEL: 'Find jobs',
    VERTICAL_NAV_FOLLOWING_LABEL: 'Following',
    VERTICAL_NAV_INSIGHTS_LABEL: 'Insights',
    VERTICAL_NAV_JOB_PREFERENCES_LABEL: 'Job preferences',
    VERTICAL_NAV_MESSAGES_LABEL: 'Messages',
    VERTICAL_NAV_MY_POSTS_LABEL: 'Your posts',
    VERTICAL_NAV_NETWORK_LABEL: 'Network',
    VERTICAL_NAV_NETWORK_SHORT_LABEL: 'Network',
    VERTICAL_NAV_PREMIUM_FOR_BASIC_USER_LABEL: 'Go Premium',
    VERTICAL_NAV_PREMIUM_LABEL: 'Premium',
    VERTICAL_NAV_PREMIUM_SHORT_LABEL: 'Premium',
    VERTICAL_NAV_PROBUSINESS_LABEL: 'ProBusiness',
    VERTICAL_NAV_PROFILE_LABEL: 'Profile',
    VERTICAL_NAV_PROJOBS_FOR_BASIC_USER_LABEL: 'Go ProJobs',
    VERTICAL_NAV_PROJOBS_LABEL: 'ProJobs',
    VERTICAL_NAV_YOUR_JOBS_LABEL: 'Your jobs',
    VERTICAL_NAVIGATION_COMPANIES: 'Companies',
    VERTICAL_NAVIGATION_FOOTER_ADVERTISE: 'Advertise on XING',
    VERTICAL_NAVIGATION_FOOTER_ANDROID: 'Android',
    VERTICAL_NAVIGATION_FOOTER_ANSCHREIBEN: 'Cover letter editor',
    VERTICAL_NAVIGATION_FOOTER_APP_GALLERY: 'Mobile & desktop apps',
    VERTICAL_NAVIGATION_FOOTER_APPS: 'Apps',
    VERTICAL_NAVIGATION_FOOTER_BEWERBUNG: 'Job application tips',
    VERTICAL_NAVIGATION_FOOTER_CAREER: 'Careers',
    VERTICAL_NAVIGATION_FOOTER_CAREER_GUIDE: 'Career Guide',
    VERTICAL_NAVIGATION_FOOTER_COACHES: 'Coaches + Trainers',
    VERTICAL_NAVIGATION_FOOTER_COMPANIES: 'Companies',
    VERTICAL_NAVIGATION_FOOTER_COMPANY: 'Company',
    VERTICAL_NAVIGATION_FOOTER_DEVBLOG: 'Devblog',
    VERTICAL_NAVIGATION_FOOTER_HALLO_FREELANCER: 'Freelancer verwalten',
    VERTICAL_NAVIGATION_FOOTER_HELP: 'Help & Contact',
    VERTICAL_NAVIGATION_FOOTER_IMPRINT: 'About this site',
    VERTICAL_NAVIGATION_FOOTER_INVESTOR_RELATIONS: 'Investor Relations',
    VERTICAL_NAVIGATION_FOOTER_IPHONE_IPAD: 'iPhone & iPad',
    VERTICAL_NAVIGATION_FOOTER_JOBS_DIRECTORY: 'Jobs Directory',
    VERTICAL_NAVIGATION_FOOTER_KUNUNU: 'Employers',
    VERTICAL_NAVIGATION_FOOTER_LEBENSLAUF: 'CV editor',
    VERTICAL_NAVIGATION_FOOTER_MEMBERSHIP_ADMINISTRATION: 'Manage memberships',
    VERTICAL_NAVIGATION_FOOTER_MEMBERSHIP_CANCELLATION: 'Cancel membership',
    VERTICAL_NAVIGATION_FOOTER_MEMBERSHIPS: 'Memberships',
    VERTICAL_NAVIGATION_FOOTER_NEW_WORK_EXPERIENCE:
      'NWX – Neues zur Zukunft der Arbeit',
    VERTICAL_NAVIGATION_FOOTER_NEWWORKSE: 'New Work SE',
    VERTICAL_NAVIGATION_FOOTER_PEOPLE_DIRECTORY: 'Member directory',
    VERTICAL_NAVIGATION_FOOTER_PRAKTIKUM_GUIDE: 'Praktikum-Guide',
    VERTICAL_NAVIGATION_FOOTER_PREMIUM_MEMBERSHIP: 'Premium',
    VERTICAL_NAVIGATION_FOOTER_PRESS: 'Press',
    VERTICAL_NAVIGATION_FOOTER_PRIVACY: 'Privacy at XING',
    VERTICAL_NAVIGATION_FOOTER_PRIVACY_POLICY: 'Privacy Policy',
    VERTICAL_NAVIGATION_FOOTER_PROJOBS_MEMBERSHIP: 'ProJobs',
    VERTICAL_NAVIGATION_FOOTER_RESOURCES: 'Resources',
    VERTICAL_NAVIGATION_FOOTER_TALENT_MANAGER: 'Recruiting with XING',
    VERTICAL_NAVIGATION_FOOTER_TANDC: 'Terms & Conditions',
    VERTICAL_NAVIGATION_FOOTER_XAS: 'Post an ad',
    VERTICAL_NAVIGATION_FOOTER_XING_NEWS: 'XING News',
    VERTICAL_NAVIGATION_FOOTER_YOUR_XING: 'Main Sections',
    VERTICAL_NAVIGATION_HEADER_BRAND_MANAGER_EDITOR: 'BrandManager',
    VERTICAL_NAVIGATION_HEADER_HELP: 'Help',
    VERTICAL_NAVIGATION_HEADER_MANAGE_JOBS: 'Job ads',
    VERTICAL_NAVIGATION_HEADER_PERSONAL_ADS: 'AdManager',
    VERTICAL_NAVIGATION_HEADER_PERSONAL_COACH_PROFILE: 'Coach profile',
    VERTICAL_NAVIGATION_HEADER_PERSONAL_LOGOUT: 'Log out',
    VERTICAL_NAVIGATION_HEADER_PERSONAL_MEMBERSHIPS_INVOICES:
      'Memberships & invoices',
    VERTICAL_NAVIGATION_HEADER_PERSONAL_PREMIUM_MEMBERSHIP: 'Premium',
    VERTICAL_NAVIGATION_HEADER_PERSONAL_PROJOBS_MEMBERSHIP: 'ProJobs',
    VERTICAL_NAVIGATION_HEADER_PERSONAL_SETTINGS: 'Settings',
    VERTICAL_NAVIGATION_HEADER_SEAT_MANAGER_ADMIN: 'LicenceManager',
    VERTICAL_NAVIGATION_HEADER_XTM: 'TalentManager',
    VERTICAL_NAVIGATION_MESSAGES: 'Messages',
    X_UI_HEADER_REGISTER: 'Sign up',
    X_UI_NAVIGATION_LOGIN_FRAME_FALLBACK: 'Log in',
    XDS_FLAG_AMBASSADOR_DIVERSE: 'Ambassador',
    XDS_FLAG_AMBASSADOR_FEMALE: 'Ambassador',
    XDS_FLAG_AMBASSADOR_MALE: 'Ambassador',
    XDS_FLAG_AMBASSADOR_NEUTRAL: 'Ambassador',
    XDS_FLAG_BASIC_DIVERSE: 'Basic',
    XDS_FLAG_BASIC_FEMALE: 'Basic',
    XDS_FLAG_BASIC_MALE: 'Basic',
    XDS_FLAG_BASIC_NEUTRAL: 'Basic',
    XDS_FLAG_BETA_DIVERSE: 'Beta',
    XDS_FLAG_BETA_FEMALE: 'Beta',
    XDS_FLAG_BETA_MALE: 'Beta',
    XDS_FLAG_BETA_NEUTRAL: 'Beta',
    XDS_FLAG_INSIDER_DIVERSE: 'Insider',
    XDS_FLAG_INSIDER_FEMALE: 'Insider',
    XDS_FLAG_INSIDER_MALE: 'Insider',
    XDS_FLAG_INSIDER_NEUTRAL: 'Insider',
    XDS_FLAG_MODERATOR_DIVERSE: 'Moderator',
    XDS_FLAG_MODERATOR_FEMALE: 'Moderator',
    XDS_FLAG_MODERATOR_MALE: 'Moderator',
    XDS_FLAG_MODERATOR_NEUTRAL: 'Moderator',
    XDS_FLAG_NEW_DIVERSE: 'New',
    XDS_FLAG_NEW_FEMALE: 'New',
    XDS_FLAG_NEW_MALE: 'New',
    XDS_FLAG_NEW_NEUTRAL: 'New',
    XDS_FLAG_PREMIUM_DIVERSE: 'Premium',
    XDS_FLAG_PREMIUM_FEMALE: 'Premium',
    XDS_FLAG_PREMIUM_MALE: 'Premium',
    XDS_FLAG_PREMIUM_NEUTRAL: 'Premium',
    XDS_FLAG_PROBUSINESS_DIVERSE: 'ProBusiness',
    XDS_FLAG_PROBUSINESS_FEMALE: 'ProBusiness',
    XDS_FLAG_PROBUSINESS_MALE: 'ProBusiness',
    XDS_FLAG_PROBUSINESS_NEUTRAL: 'ProBusiness',
    XDS_FLAG_PROCOACH_DIVERSE: 'ProCoach',
    XDS_FLAG_PROCOACH_FEMALE: 'ProCoach',
    XDS_FLAG_PROCOACH_MALE: 'ProCoach',
    XDS_FLAG_PROCOACH_NEUTRAL: 'ProCoach',
    XDS_FLAG_PROJOBS_DIVERSE: 'ProJobs',
    XDS_FLAG_PROJOBS_FEMALE: 'ProJobs',
    XDS_FLAG_PROJOBS_MALE: 'ProJobs',
    XDS_FLAG_PROJOBS_NEUTRAL: 'ProJobs',
    XDS_FLAG_PROTRAINER_DIVERSE: 'ProTrainer',
    XDS_FLAG_PROTRAINER_FEMALE: 'ProTrainer',
    XDS_FLAG_PROTRAINER_MALE: 'ProTrainer',
    XDS_FLAG_PROTRAINER_NEUTRAL: 'ProTrainer',
  },
};
